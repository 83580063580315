import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent }  from '@angular/common/http';
  import { BASE_PATH, COLLECTION_FORMATS } from './variables';
import { Observable } from 'rxjs';
import { Configuration }  from './configuration';
import { CustomHttpUrlEncodingCodec }  from './encoder';

import { SitesConfig, User } from './model/user';
import { ListaJogos } from './model/jogos';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  //public basePath = 'http://170.79.208.126:3000/api/v2';
  //public basePath = 'https://mtsapi.rodriguestech.com/api/v2';
  public basePath = 'https://mtsapi.betpremiado.vip/api/v2';
  public accesstoken = ''; // <-- Enter your own key here!
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) { 
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
        this.configuration = configuration;
        this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
      private canConsumeForm(consumes: string[]): boolean {
      const form = 'multipart/form-data';
      for (const consume of consumes) {
          if (form === consume) {
              return true;
          }
      }
      return false;
  }

  /**
     * Create a new Session
     * 
     * @param username 
     * @param password 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
   public sessionPost(email: string, password: string, observe?: 'body', reportProgress?: boolean): Observable<User>;
   public sessionPost(email: string, password: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
   public sessionPost(email: string, password: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
   public sessionPost(email: string, password: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


       if (email === null || email === undefined) {
           throw new Error('Required parameter email was null or undefined when calling sessionPost.');
       }else{
         this.configuration.email = email;
       }

       if (password === null || password === undefined) {
           throw new Error('Required parameter password was null or undefined when calling sessionPost.');
       }else{
           this.configuration.password = password;
       }

       let headers = this.defaultHeaders;

       // authentication (basicAuth) required
       if (this.configuration.email || this.configuration.password) {
        headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.email + ':' + this.configuration.password));
      }

       // to determine the Accept header
       let httpHeaderAccepts: string[] = [
           'application/json'
       ];
       const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
       if (httpHeaderAcceptSelected != undefined) {
           headers = headers.set('Accept', httpHeaderAcceptSelected);
       }

       // to determine the Content-Type header
       const consumes: string[] = [
           'application/x-www-form-urlencoded'
       ];

       const canConsumeForm = this.canConsumeForm(consumes);

       let formParams: { append(param: string, value: any): void | HttpParams; };
       let useForm = false;
       let convertFormParamsToString = false;
       if (useForm) {
           formParams = new FormData();
       } else {
           formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
       }

       if (email !== undefined) {
           formParams = formParams.append('email', <any>email) || formParams;
       }
       if (password !== undefined) {
           formParams = formParams.append('password', <any>password) || formParams;
       }

       return this.httpClient.post<User>(`${this.basePath}/user/auth`,
           convertFormParamsToString ? formParams.toString() : formParams,
           {
               withCredentials: false,
               headers: headers,
               observe: observe,
               reportProgress: reportProgress
           }
       );
   }

    /**
     * Fetch a list of Configus
     * 
     * @param userId Can only be used by admin or manager users
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
        public configGetAllSite(token?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SitesConfig>>;
        public configGetAllSite(token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SitesConfig>>>;
        public configGetAllSite(token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SitesConfig>>>;
        public configGetAllSite(token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    
          let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
          /*
          if (baseaposta !== undefined && baseaposta !== null) {
              queryParameters = queryParameters.set('', <any>baseaposta);
          }*/
    
          let headers = this.defaultHeaders;
    
          // authentication (basicAuth) required
          if (this.configuration.email || this.configuration.password) {
              headers = headers.set('Authorization', 'Bearer ' + token);
          }
    
          // to determine the Accept header
          let httpHeaderAccepts: string[] = [
              'application/json'
          ];
          const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
          if (httpHeaderAcceptSelected != undefined) {
              headers = headers.set('Accept', httpHeaderAcceptSelected);
          }
    
          // to determine the Content-Type header
          const consumes: string[] = [
              'application/json'
          ];
      
    
          return this.httpClient.get<Array<SitesConfig>>(`${this.basePath}/config/site`,
              {
                  //params: queryParameters,
                  withCredentials: this.configuration.withCredentials,
                  headers: headers,
                  observe: observe,
                  reportProgress: reportProgress
              }
          );
      }

    /**
     * Fetch a list of Users
     * 
     * @param userId Can only be used by admin or manager users
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public calcGetAllJogos(baseaposta?: number, suremax?: number, siteconfigs?: SitesConfig[], token?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ListaJogos>>;
     public calcGetAllJogos(baseaposta?: number, suremax?: number, siteconfigs?: SitesConfig[], token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ListaJogos>>>;
     public calcGetAllJogos(baseaposta?: number, suremax?: number, siteconfigs?: SitesConfig[], token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ListaJogos>>>;
     public calcGetAllJogos(baseaposta?: number, suremax?: number, siteconfigs?: SitesConfig[], token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
       let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
       if (baseaposta !== undefined && baseaposta !== null) {
           queryParameters = queryParameters.set('', <any>baseaposta);
       }
 
       let headers = this.defaultHeaders;
 
       // authentication (basicAuth) required
       if (this.configuration.email || this.configuration.password) {
           headers = headers.set('Authorization', 'Bearer ' + token);
       }
 
       // to determine the Accept header
       let httpHeaderAccepts: string[] = [
           'application/json'
       ];
       const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
       if (httpHeaderAcceptSelected != undefined) {
           headers = headers.set('Accept', httpHeaderAcceptSelected);
       }
 
       // to determine the Content-Type header
       const consumes: string[] = [
           'application/json'
       ];

       var jsonParams = {
           base: baseaposta,
           suremax: suremax,
           siteconfigs: siteconfigs
       }

 
       return this.httpClient.get<Array<ListaJogos>>(`${this.basePath}/calc/`+<any>JSON.stringify(jsonParams),
           {
               //params: queryParameters,
               withCredentials: this.configuration.withCredentials,
               headers: headers,
               observe: observe,
               reportProgress: reportProgress
           }
       );
   }

   /**
     * Fetch a list of Users
     * 
     * @param userId Can only be used by admin or manager users
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public calcGetAllJogosVirtual(baseaposta?: number, suremax?: number, siteconfigs?: SitesConfig[], token?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ListaJogos>>;
    public calcGetAllJogosVirtual(baseaposta?: number, suremax?: number, siteconfigs?: SitesConfig[], token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ListaJogos>>>;
    public calcGetAllJogosVirtual(baseaposta?: number, suremax?: number, siteconfigs?: SitesConfig[], token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ListaJogos>>>;
    public calcGetAllJogosVirtual(baseaposta?: number, suremax?: number, siteconfigs?: SitesConfig[], token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

      let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
      if (baseaposta !== undefined && baseaposta !== null) {
          queryParameters = queryParameters.set('', <any>baseaposta);
      }

      let headers = this.defaultHeaders;

      // authentication (basicAuth) required
      if (this.configuration.email || this.configuration.password) {
          headers = headers.set('Authorization', 'Bearer ' + token);
      }

      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
          'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
          'application/json'
      ];

      var jsonParams = {
          base: baseaposta,
          suremax: suremax,
          siteconfigs: siteconfigs
      }


      return this.httpClient.get<Array<ListaJogos>>(`${this.basePath}/calc/virtual/`+<any>JSON.stringify(jsonParams),
          {
              //params: queryParameters,
              withCredentials: this.configuration.withCredentials,
              headers: headers,
              observe: observe,
              reportProgress: reportProgress
          }
      );
  }

    /**
     * Fetch a list of Users
     * 
     * @param userId Can only be used by admin or manager users
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
        public calcGetAllBaskJogos(baseaposta?: number, suremax?: number, siteconfigs?: SitesConfig[], token?: string, type?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ListaJogos>>;
        public calcGetAllBaskJogos(baseaposta?: number, suremax?: number, siteconfigs?: SitesConfig[], token?: string, type?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ListaJogos>>>;
        public calcGetAllBaskJogos(baseaposta?: number, suremax?: number, siteconfigs?: SitesConfig[], token?: string, type?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ListaJogos>>>;
        public calcGetAllBaskJogos(baseaposta?: number, suremax?: number, siteconfigs?: SitesConfig[], token?: string, type?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    
          let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
          if (baseaposta !== undefined && baseaposta !== null) {
              queryParameters = queryParameters.set('', <any>baseaposta);
          }
    
          let headers = this.defaultHeaders;
    
          // authentication (basicAuth) required
          if (this.configuration.email || this.configuration.password) {
              headers = headers.set('Authorization', 'Bearer ' + token);
          }
    
          // to determine the Accept header
          let httpHeaderAccepts: string[] = [
              'application/json'
          ];
          const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
          if (httpHeaderAcceptSelected != undefined) {
              headers = headers.set('Accept', httpHeaderAcceptSelected);
          }
    
          // to determine the Content-Type header
          const consumes: string[] = [
              'application/json'
          ];
   
          var jsonParams = {
              base: baseaposta,
              suremax: suremax,
              siteconfigs: siteconfigs,
              tipo: type
          }
   
    
          return this.httpClient.get<Array<ListaJogos>>(`${this.basePath}/calc/basketball/`+<any>JSON.stringify(jsonParams),
              {
                  //params: queryParameters,
                  withCredentials: this.configuration.withCredentials,
                  headers: headers,
                  observe: observe,
                  reportProgress: reportProgress
              }
          );
      }
   
    /**
     * Fetch a list of Users
     * 
     * @param userId Can only be used by admin or manager users
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
        public calcGetAllHandJogos(baseaposta?: number, suremax?: number, siteconfigs?: SitesConfig[], token?: string, type?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ListaJogos>>;
        public calcGetAllHandJogos(baseaposta?: number, suremax?: number, siteconfigs?: SitesConfig[], token?: string, type?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ListaJogos>>>;
        public calcGetAllHandJogos(baseaposta?: number, suremax?: number, siteconfigs?: SitesConfig[], token?: string, type?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ListaJogos>>>;
        public calcGetAllHandJogos(baseaposta?: number, suremax?: number, siteconfigs?: SitesConfig[], token?: string, type?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    
          let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
          if (baseaposta !== undefined && baseaposta !== null) {
              queryParameters = queryParameters.set('', <any>baseaposta);
          }
    
          let headers = this.defaultHeaders;
    
          // authentication (basicAuth) required
          if (this.configuration.email || this.configuration.password) {
              headers = headers.set('Authorization', 'Bearer ' + token);
          }
    
          // to determine the Accept header
          let httpHeaderAccepts: string[] = [
              'application/json'
          ];
          const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
          if (httpHeaderAcceptSelected != undefined) {
              headers = headers.set('Accept', httpHeaderAcceptSelected);
          }
    
          // to determine the Content-Type header
          const consumes: string[] = [
              'application/json'
          ];
   
          var jsonParams = {
              base: baseaposta,
              suremax: suremax,
              siteconfigs: siteconfigs,
              tipo: type
          }
   
    
          return this.httpClient.get<Array<ListaJogos>>(`http://170.79.208.126:2040/dados/alldata`,
              {
                  //params: queryParameters,
                  withCredentials: this.configuration.withCredentials,
                  headers: headers,
                  observe: observe,
                  reportProgress: reportProgress
              }
          );
      }

      
   
   /**
     * Fetch a list of Users
     * 
     * @param userId Can only be used by admin or manager users
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userGet(userId?: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<User>>;
    public userGet(userId?: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<User>>>;
    public userGet(userId?: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<User>>>;
    public userGet(userId?: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

      let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
      if (userId !== undefined && userId !== null) {
          queryParameters = queryParameters.set('', <any>userId);
      }

      let headers = this.defaultHeaders;

      // authentication (basicAuth) required
      if (this.configuration.email || this.configuration.password) {
          headers = headers.set('Authorization', 'Bearer ' + token);
      }

      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
          'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
          'application/json'
      ];

      return this.httpClient.get<Array<User>>(`${this.basePath}/user/getuser/`+<any>userId,
          {
              //params: queryParameters,
              withCredentials: this.configuration.withCredentials,
              headers: headers,
              observe: observe,
              reportProgress: reportProgress
          }
      );
  }

      /**
     * Fetch a list of Users
     * 
     * @param userId Can only be used by admin or manager users
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
       public tokenVerify(userId?: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ListaJogos>>;
       public tokenVerify(userId?: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ListaJogos>>>;
       public tokenVerify(userId?: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ListaJogos>>>;
       public tokenVerify(userId?: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
   
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (userId !== undefined && userId !== null) {
             queryParameters = queryParameters.set('', <any>userId);
         }
   
         let headers = this.defaultHeaders;
   
         // authentication (basicAuth) required
         if (this.configuration.email || this.configuration.password) {
             headers = headers.set('Authorization', 'Bearer ' + token);
         }
   
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
   
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
  
         var jsonParams = {
             token: token,
             userId: userId,
         }
  
   
         return this.httpClient.get<Array<ListaJogos>>(`${this.basePath}/user/tokenverify/`+<any>JSON.stringify(jsonParams),
             {
                 //params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

  public getPosts(customer?: string, observe?: 'body', reportProgress?: boolean) {
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (customer !== undefined && customer !== null) {
      queryParameters = queryParameters.set('customer', <any>customer);
    }

    let headers = this.defaultHeaders;

    // authentication (Token) required
     headers = headers.set('access_token', this.accesstoken);
     headers = headers.set('Accept', 'application/json');

    // to determine the Content-Type header
    const consumes: string[] = [
        'application/json'
    ];

    //return this.httpClient.get<Array<Payments>>(`${this.basePath}/payments`,
      return this.httpClient.get(`${this.basePath}/payments`,
        {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        }
    ).pipe(res=> res as any);
  } 




}
